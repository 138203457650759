import React from "react"
import Slider from "react-slick"
import { Row, Col, Carousel, Tabs, Tab } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTruck, faHome } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"

import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"

const ProductLanding = ({ data, location }) => {
  return (
    <Layout>
      <SEO title={data.product.name} description={data.product.description} />
      <InnerWrapper>
        <Row className="productpagemargin">
          <Col md={6} className="item prodleft">
            <Carousel controls={data.product.imagePaths.length > 1}>
              {data.product.imagePaths.map((image) => (
                <Carousel.Item
                  key={image}
                  style={{ backgroundImage: `url(${image})` }}
                  className="product-slide"
                />
              ))}
            </Carousel>
            {/*<img src={data.product.imagePaths[0]} className="productmainimg" />
            <Slider className="slick productcarousel" {...settings}>
              {data.product.imagePaths.map((path, index) => (
                <div className="item product_thumb" key={index}>
                  <img src={path} className="product_thumb_img" />
                </div>
              ))}
              </Slider>*/}
            {/* <a href="" className="_portfolio_link">
              View portfolio examples
            </a> */}
          </Col>
          <Col md={6} className="prodright">
            <div className="summary entry-summary">
              <h1 className="product_title entry-title">{data.product.name}</h1>
              {/* <p className="price">
                <span className="Price-amount amount">
                  <span className="Price-currencySymbol">$</span>
                  18.00
                </span>
              </p> */}
              <div className="product-details__short-description">
                <p>{data.product.description}</p>
                {/* <ul>
                  <li>Lorem ipsum dolor sit amet</li>
                  <li>Tuemim ad minom veniuam</li>
                  <li>Rephrendrit in voluptatevit</li>
                </ul> */}
              </div>

              {/* <form
                className="cart"
                action="#"
                method="post"
                encType="multipart/form-data"
              >
                <div className="quantity">
                  <input
                    type="number"
                    id="quantity_5f87881f955a3"
                    className="input-text qty text form-control"
                    step="1"
                    min="1"
                    max=""
                    name="quantity"
                    defaultValue="1"
                    title="Qty"
                    size="4"
                    placeholder=""
                    inputMode="numeric"
                  />
                </div>
                <button
                  type="submit"
                  name="add-to-cart"
                  defaultValue=""
                  className="single_add_to_cart_button button alt"
                >
                  Add to cart
                </button>
              </form> */}

              <div className="product_meta">
                <span className="sku_wrapper">
                  SKU:
                  <span className="sku">{data.product.sku}</span>
                </span>{" "}
                <span className="posted_in">
                  {/* Categories:{" "}
                  {data.product.categories.map((cat) => (
                    <a href="#" rel="tag">
                      {cat.name}
                    </a>
                  ))} */}
                  {/* <a href="#" rel="tag">
                    Category 1
                  </a>
                  ,{" "}
                  <a href="#" rel="tag">
                    Category 2
                  </a> */}
                </span>
              </div>

              {data.product.storeUrl && (
                <a
                  className="product_cta"
                  href={`https://shop.walpoleoutdoors.com/${data.product.storeUrl}`}
                >
                  BUY NOW
                </a>
              )}

              <table className="_add_list">
                <tbody>
                  <tr>
                    <td style={{ width: "20px" }}>
                      <FontAwesomeIcon icon={faTruck} className="fa-product" />
                    </td>
                    <td>UPS Delivery To Your Home</td>
                  </tr>
                  {/*<tr>
                    <td>
                      <FontAwesomeIcon icon={faHome} className="fa-product" />
                    </td>
                    <td>Free Delivery To Nearest Walpole Location</td>
                  </tr>*/}
                </tbody>
              </table>
            </div>
          </Col>
          {/*<Tabs defaultActiveKey="description" className="product_tabs">
            <Tab
              eventKey="description"
              title="Description"
              tabClassName="tab_title product_tab"
            >
              <p>
                Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas. Vestibulum tortor quam,
                feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu
                libero sit amet quam egestas semper. Aenean ultricies mi vitae
                est. Mauris placerat eleifend leo.
              </p>
            </Tab>
            <Tab
              eventKey="additional_info"
              title="Additional Information"
              tabClassName="tab_title product_tab"
            >
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt.
              </p>
            </Tab>
            <Tab
              eventKey="reviews"
              title="Reviews"
              tabClassName="tab_title product_tab"
            >
              <p>
                Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, sed quia non numquam eius modi
                tempora incidunt ut labore et dolore magnam aliquam quaerat
                voluptatem. Ut enim ad minima veniam, quis nostrum
                exercitationem ullam corporis suscipit laboriosam, nisi ut
                aliquid ex ea commodi consequatur? Quis autem vel eum iure
                reprehenderit qui in ea voluptate velit esse quam nihil
                molestiae consequatur, vel illum qui dolorem eum fugiat quo
                voluptas nulla pariatur?
              </p>
            </Tab> 
          </Tabs>*/}

          {/* <h2 className="sectionheader">Related Products</h2>

          <Container fluid>
            <Row className="relatedgrid">
              <Col md={3} className="item relatedproduct">
                <a className="_link" href="#"></a>
                <figure className="gridbg2"></figure>
                <h4>T-Shirt with Logo</h4>
              </Col>
              <Col md={3} className="item relatedproduct">
                <a className="_link" href="#"></a>
                <figure className="gridbg2"></figure>
                <h4>Hoodie with Logo</h4>
              </Col>
              <Col md={3} className="item relatedproduct">
                <a className="_link" href="#"></a>
                <figure className="gridbg2"></figure>
                <h4>Hoodie</h4>
              </Col>
              <Col md={3} className="item relatedproduct">
                <a className="_link" href="#"></a>
                <figure className="gridbg2"></figure>
                <h4>Hoodie</h4>
              </Col>
            </Row>
          </Container>

          <div className="featured_post">
            <h2 className="sectionheader">Outdoor Living</h2>
            <div className="inner">
              <div className="row align-items-center">
                <div className="col-12 col-md-6 col-lg-4">
                  <figure className="blogfeatbg"></figure>
                </div>
                <div className="col-12 col-md-6 col-lg-8">
                  <h3>Lorem ipsum dolor sit amet</h3>
                  <div className="meta">
                    Posted on June 8, 2020 by Walpole Outdoors
                  </div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  feugiat nunc diam, eu efficitur tortor sollicitudin ut.
                  Phasellus ullamcorper eu sapien nec mollis. Vestibulum dictum
                  eleifend magna ut elementum. Etiam semper tempus turpis
                  venenatis pretium....{" "}
                  <a
                    href="https://walpoleoutdoor.wpengine.com/hello-world/"
                    className="more"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default ProductLanding

export const query = graphql`
  query($slug: String!) {
    product(slug: { eq: $slug }) {
      name
      slug
      description
      sku
      imagePaths
      storeUrl
    }
  }
`
